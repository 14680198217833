<template>
  <div class="sign-container">
    <StaggerTransition>
      <ul class="sns-sign ani-stagger">
        <li>
          <NaverLogin
            type="signUp"
          />
        </li>
        <!-- <li>
          <FacebookLogin
            type="signUp"
            @authenticated="onAuthenticated"
          />
        </li> -->
        <li>
          <GoogleLogin
            type="signUp"
            @authenticated="onAuthenticated"
          />
        </li>
        <li>
          <KakaoLogin
            type="signUp"
            @authenticated="onAuthenticated"
          />
        </li>
      </ul>
    </StaggerTransition>
  </div>
</template>

<script>
import NaverLogin from '@/views/auth/NaverLogin.vue';
import FacebookLogin from '@/views/auth/FacebookLogin.vue';
import GoogleLogin from '@/views/auth/GoogleLogin.vue';
import KakaoLogin from '@/views/auth/KakaoLogin.vue';
import { mixin as mixinHelperUtils, mixinAuth } from '@/common/helperUtils';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';

export default {
  name: 'SignUpStep1',
  mixins: [
    mixinHelperUtils,
    mixinAuth,
  ],
  components: {
    NaverLogin,
    FacebookLogin,
    GoogleLogin,
    KakaoLogin,
    StaggerTransition,
  },
  props: {
    loginStauts: {
      type: Number,
    },
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  created() {
    this.setFooter(false);
  },
  mounted() {
    if (this.loginStauts === 623) {
      this.showAlert(this.$t('errorMessage.usedEmail'));
    } else if (this.loginStauts === 608) {
      this.showAlert(this.$t('errorMessage.notFoundEmail'));
    }
  },
  methods: {
    onAuthenticated(oauthUserInfo) {
      if (!oauthUserInfo.email) {
        this.showAlert(this.$t('errorMessage.invalidEmail'));
        return;
      }
      this.signUp(oauthUserInfo); // mixinAuth
    },
  },
};
</script>
