<template>
  <div class="sign-container">
    <StaggerTransition>
      <ul class="sns-sign ani-stagger">
        <li>
          <NaverLogin
            type="signUp"
          />
        </li>
        <!-- <li>
          <FacebookLogin
            type="signUp"
            @authenticated="onAuthenticated"
          />
        </li> -->
        <li>
          <GoogleLogin
            type="signUp"
            @authenticated="onAuthenticated"
          />
        </li>
        <li>
          <KakaoLogin
            type="signUp"
            @authenticated="onAuthenticated"
          />
        </li>
      </ul>
    </StaggerTransition>
  </div>
</template>

<script>
import SignUpStep1 from '@/views/auth/SignUpStep1.vue';

export default {
  name: 'MobileSignUpStep1',
  extends: SignUpStep1,
};
</script>
<style scoped>
.sign-area .sns-sign .sns-item { display: block; height: 5.6rem; line-height: 5.6rem; text-align: center; color: #fff; font-size: 1.6rem; font-weight: 700; 
background-image: url("../../assets/m_images/icons_login_2.png") !important; background-repeat: no-repeat; background-size: 5.6rem auto; }
</style>
